import Head from 'next/head';
import { PropsWithChildren } from 'react';
import { DatoSEO } from '../../../types/dato';

export type SeoTagsProps = PropsWithChildren<{
  tags: DatoSEO;
}>;

export const SeoTags = ({ children, tags }: SeoTagsProps) => {
  return (
    <Head>
      {tags.map((tag, index) => {
        if (tag.tag === 'title') {
          return <title key={index}>{tag.content}</title>;
        }
        if (tag.tag === 'meta') {
          return (
            <meta
              key={index}
              property={tag.attributes?.property}
              name={tag.attributes?.name}
              content={tag.attributes?.content}
            >
              {tag.content}
            </meta>
          );
        }
        return null;
      })}

      {children}
    </Head>
  );
};
